/**
* Generated automatically at built-time (2025-03-27T20:10:17.933Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { PageBuilderBodyBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";
export default ({screen, siteContext})=>{
const helmet = mergeHelmet(siteContext.helmet, screen.helmet);
const pageId = "3d-guide-outdoor-kitchen-page";
return {[pageId]: {id: pageId,helmet,className: getComponentClassName(pageId,null,PageBuilderBodyBS),items: getScreenTemplates(screen)}};
}